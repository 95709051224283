import styled from 'styled-components'
import { colors } from './../../styles/vars'

const UrlListDiv = styled.div`
  padding: 0;
  & > a {
    display: block;
    padding: 1.6rem 0;
    border-bottom: 2px solid ${colors.red};
    padding-bottom: 1.3rem;
    font-weight: bold;
    color: ${colors.red};
    font-size: 2rem;
    text-decoration: none;
    cursor: pointer;
    .blue {
      color: ${colors.lightBlue};
    }
    span.remove-button {
      transition: all 0.3s ease;
      float: right;
      display: block;
      margin-right: 10px;
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      cursor: pointer;
      &::after,
      &::before {
        top: 0;
        left: 50%;
        position: absolute;
        display: block;
        transform-origin: center center;
        content: '';
        width: 0.3rem;
        height: 2rem;
        background-color: ${colors.red};
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
      &:hover {
        transform: rotate(-90deg);
      }
    }
    &:hover {
      background-color: ${colors.grey};
    }
  }
`

const UrlGridUI = styled.div`
  display: block;
  list-style: none;
  padding: 0;
  margin: -10px;
  & > a {
    display: inline-block;
    height: 10vw;
    width: 25%;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 5%;
    box-sizing: border-box;
    background-color: ${colors.lightBlue};
  }
`

const AddContainer = styled.div`
  padding: 0.8rem 1rem;
  background-color: ${colors.lightBlue};
  border-radius: 4rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  span {
    margin: 0.2rem;
  }
  &.active {
    span {
      display: inline-block;
      transform: rotate(45deg);
      cursor: pointer;
      text-align: center;
    }
  }
  input {
    background-color: transparent;
    border: 0;
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  button:focus,
  input:focus {
    outline: 0;
  }
  button.add-site {
    background-color: transparent;
    border: 0;
    position: relative;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
    }
  }
  &:hover {
  }
`

export { UrlListDiv, UrlGridUI, AddContainer }
