import React, { useState, useEffect } from 'react'
import { withFirebase } from '../Firebase'
import styled from 'styled-components'
import { validURL } from '../../utils/index'
import { AddContainer } from './UrlsStyle'
import { FormPillow } from '../../styles/globalStyles'

const Container = styled.div`
  color: blue;
`

const AddUrl = ({ authUser, firebase }) => {
  const [newUrl, setNewUrl] = useState('')
  const [showForm, setShowForm] = useState(false)

  const addUrl = () => {
    if (!firebase || !authUser) {
      return
    }
    const isValid = validURL(newUrl)
    if (!isValid) {
      alert('Not valid url')
      return
    }
    const urlId = firebase.urls(authUser.uid).push().key
    firebase.urls(authUser.uid + '/' + urlId).set(newUrl)
    alert(newUrl + ' added')
    setNewUrl('')
  }
  return (
    <AddContainer className={showForm && 'active'}>
      <span
        onClick={() => {
          if (showForm) {
            setNewUrl('')
            setShowForm(false)
          } else {
            setShowForm(true)
          }
        }}
      >
        &nbsp;+&nbsp;
      </span>
      {showForm && (
        <input
          value={newUrl}
          type='text'
          name='addurl'
          onChange={e => setNewUrl(e.target.value)}
        />
      )}
      <button
        className='add-site'
        onClick={() => {
          if (showForm) {
            addUrl()
          } else {
            setShowForm(true)
          }
        }}
      >
        Add site
      </button>
    </AddContainer>
  )
}

export default withFirebase(AddUrl)
