import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'
import { UrlListDiv } from './UrlsStyle'
import { cleanUrl, capitalizeFirst } from './../../utils'

const UrlList = ({ firebase = null, authUser }) => {
  const [fetching, setFetching] = useState(false)
  const [urls, setUrls] = useState([])

  const removeUrl = urlId => {
    firebase.urls(authUser.uid + '/' + urlId).remove()
  }
  const startFetching = () => {
    setFetching(true)
    firebase &&
      firebase.urls(authUser.uid).on('value', snap => {
        const data = snap.val()
        data && setUrls(Object.entries(data))
      })
  }
  useEffect(() => {
    startFetching()
  }, [firebase])
  return (
    <div>
      <UrlListDiv>
        {/* <a>Add new</a> */}
        {urls.map(a => (
          <Link key={a[0]} to={'/dash/?url=' + encodeURIComponent(a[1])}>
            {capitalizeFirst(cleanUrl(a[1]))}
            <span
              className='remove-button'
              onClick={() => {
                removeUrl(a[0])
              }}
            ></span>
          </Link>
        ))}
      </UrlListDiv>
    </div>
  )
}

export default withFirebase(UrlList)
