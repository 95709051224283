import React from 'react'
import { compose } from 'recompose'

import Layout from '../components/layout'
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session'
// import Messages from '../components/Messages'
import { StartContainer } from './../styles/startStyles'
import {
  FormPillow,
  CircleIllu,
  PageHeaderMargin,
  PageHeader,
} from './../styles/globalStyles'
import UrlList from '../components/Urls/UrlList'
import AddUrl from '../components/Urls/AddUrl'

const HomePageBase = (props) => {
  return (
    <>
      <AuthUserContext.Consumer>
        {(authUser) => {
          console.log(authUser.uid)
          const slackUrl =
            'https://slack.com/oauth/v2/authorize?client_id=6314329650.1057017949109&scope=chat:write,chat:write.public,channels:read&state=' +
            authUser.uid
          return (
            <>
              <StartContainer>
                <PageHeaderMargin />
                <PageHeader>
                  <a href={slackUrl}>
                    <img
                      alt='Add to Slack'
                      height='40'
                      width='139'
                      src='https://platform.slack-edge.com/img/add_to_slack.png'
                    ></img>
                  </a>
                  <h1>Your sites</h1>
                  <AddUrl authUser={authUser} />
                </PageHeader>
                {/* <p>Here is your dashboard {authUser.email}</p> */}
                <FormPillow>
                  <UrlList authUser={authUser} />
                </FormPillow>
              </StartContainer>
              <CircleIllu xxl />
            </>
          )
        }}
      </AuthUserContext.Consumer>
    </>
  )
}

const condition = (authUser) => !!authUser

const HomePage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePageBase)

export default () => (
  <Layout>
    <HomePage />
  </Layout>
)
